import React, { useState, useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CFormInput,
  CInputGroup,
  CRow,
  CForm,
  CInputGroupText,
} from "@coreui/react";
import { useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import Versioning from "../../../common/versioning";
import Cryptr from "cryptr";
import { updateToken } from "../../../helpers/Helpers";
import logo from "../../../assets/img/brand/logo.png";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fas, fab);

const cookies = new Cookies();

/**
 * Questa vista permetta la visualizzazione della pagina di login per l'accesso
 * al personale autorizzato. <br>
 * <hr>
 *
 * I dati per effettuare il login vengono richiamati dall'helper (Helper.js) <br>
 *
 * @property {object} location Oggetto JSON che contiene l'URL della maschera compresi i parametri
 * <pre><div style="line-height:1.3em">
 * {
 *  "location": {
 *     "state":{
 *       "from": {
 *         "pathname": "value"
 *       }
 *     }
 *   }
 * }
 * </div></pre>
 * @property {object} history Oggetto JSON che contiene la history del browser
 * <pre><div style="line-height:1.3em">
 * {
 *  "history": {
 *     "state":{
 *       "from": {
 *         "pathname": "value"
 *       }
 *     }
 *   }
 * }
 * </div></pre>
 * @category viste-standard
 * @subcategory pages
 */

const Login = (props) => {
  const [usernameValue, setUsername] = useState("");
  const [passwordValue, setPassword] = useState("");
  const [redirectToReferrer, setRedirectToReferrer] = useState(
    cookies.get("JEProjectJWTTK") !== undefined
  );
  const [resultValue, setResult] = useState("");
  const [usernameErrato, setUsernameErrato] = useState(false);
  const [passwordErrata, setPasswordErrata] = useState(false);
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    if (redirectToReferrer === true) {
      console.log(redirectToReferrer);
      return <Redirect to={from} />;
    }

    checkUUIDError();
  }, []);

  const checkUUIDError = () => {
    if (cookies.get("JEProjectUUIDError")) {
      let cryptr = new Cryptr(process.env.REACT_APP_ENCRYPTDBINFO);
      const UUIDError = cryptr.decrypt(cookies.get("JEProjectUUIDError"));
      if (UUIDError === "true") {
        setResult(
          "Accesso rilevato da un altra macchina, eseguire nuovamente il login."
        );
      }
      cookies.remove("JEProjectUUIDError");
    }
  };

  /**
   * Metodo handler per la gestione del login
   */
  const handleLogin = () => {
    callLogin();
  };

  /**
   * Metodo handler per la gestione del submit del form
   * @param {object} event
   */
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  /**
   * Metodo per la chiamata al servizio di login
   */
  const callLogin = () => {
    var username = usernameValue;
    var password = passwordValue;
    var durataToken =
      process.env.REACT_APP_NDAYS_COOKIE > 0
        ? process.env.REACT_APP_NDAYS_COOKIE
        : 1;
    let redirectPath = null;
    let encodedRouteParameters = null;
    let pathname = null;

    pathname = location.state?.from?.pathname || "/";

    if (pathname != null) {
      let ar = pathname.split(":");
      encodedRouteParameters = ar[1];
      if (ar[0].startsWith("/")) {
        redirectPath = ar[0].substring(1, ar[0].length);
      }
      if (redirectPath.endsWith("/")) {
        redirectPath = redirectPath.substring(0, redirectPath.length - 1);
      }
    }

    if (cookies.get("JEProjectUUIDError")) {
      cookies.remove("JEProjectUUIDError");
    }

    updateToken(
      "JEProjectJWTTK",
      durataToken,
      "JEProjectTMP",
      {
        username,
        password,
      },
      props.history,
      redirectPath,
      encodedRouteParameters,
      function (error) {
        setResult(error);
      }
    );
  };

  /**
   * Metodo per l'aggiornamento dello stato del campo username
   * @param {unknown} evt
   */
  const updateUsernameValue = (evt) => {
    setUsername(evt.target.value);
  };

  /**
   * Metodo per l'aggiornamento dello stato del campo password
   * @param {unknown} evt
   */
  const updatePwdValue = (evt) => {
    setPassword(evt.target.value);
  };

  /**
   * @returns {Login}
   */
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer className="loginPage">
        <CRow className="justify-content-center">
          <CCol md="6" lg="4">
            <CCard>
              <CCardBody>
                <CForm onSubmit={(event) => handleSubmit(event)}>
                  <CRow className="justify-content-center">
                    <CCol xs="8">
                      <img
                        src={logo}
                        className="img-fluid center-block"
                        style={{ width: "100%" }}
                        alt="Logo Aziendale"
                      />
                    </CCol>
                  </CRow>
                  <p className="text-muted text-center">
                    Accedi al tuo account
                  </p>
                  <CInputGroup className="mb-2">
                    <CInputGroupText>
                      <FontAwesomeIcon icon={["fas", "user"]} />
                    </CInputGroupText>

                    <CFormInput
                      type="text"
                      placeholder="Username"
                      value={usernameValue}
                      onChange={updateUsernameValue}
                    />
                  </CInputGroup>

                  <CInputGroup className="mb-4">
                    <CInputGroupText>
                      <FontAwesomeIcon icon={["fas", "lock"]} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      placeholder="Password"
                      onChange={updatePwdValue}
                    />
                  </CInputGroup>
                  <CRow className="justify-content-center">
                    <CCol xs="6" className="d-grid gap-25">
                      <CButton
                        /*block*/ color="primary"
                        type="submit"
                        className=""
                        onClick={(event) => handleLogin(event)}
                      >
                        Login
                      </CButton>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol xs="12">
                      <p className="text-muted text-center">{resultValue}</p>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol xs="12">
                      <p className="text-muted text-center m-0">
                        <Versioning className="versioneLogin" />
                      </p>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
